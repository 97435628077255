import logo from './logo.svg';
import './App.css';
import { Avatar, Card, Col, Row, Modal, Typography } from 'antd';
import monopioscreen from './images/monopioscreen.png';
import wptscreen from './images/wptscreen.png';
import blsscreen from './images/blsscreen.png';
import d3screen from './images/d3landing.png';
import aascreen from './images/aascreen.png';
import ticket from './images/ticket_summary.png';
import { useEffect, useState } from 'react';
import './Examples.css';

import badge from './images/badge-psmi.svg';
import { useNavigate } from 'react-router-dom';
function Examples() {
  const nav = useNavigate();

  const [modal, contextHolder] = Modal.useModal();

  const toggleContactOpen = () => setContactOpen(!contactOpen);
  const [contactOpen, setContactOpen] = useState(false);

  useEffect(() => {
    if (contactOpen)
      modal.info({
        title: '😃',
        content: <code>your looking at it</code>,
        icon: null
      })
    setContactOpen(false);
  }, [contactOpen])
  

  return (
    <div >
      <div>{contextHolder}</div>
      <section className="Examples-body">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        
       
        <Typography.Title level={3}>
          Websites
        </Typography.Title>
        <Row gutter={[30, 30]}>
          
          <Col className='example-link hover-link' flex={'1 1 200px'}  onClick={() => window.location.assign('/wptexample')}>
            <Card  
              title='World Peace Token'
              size='small'
              style={{ background: 'rgb(20 104 255)'}}
              cover={
                <img
                  alt="world peace token example"
                  src={wptscreen}
                  style={{ width: 600, margin: '0 auto', maxWidth: '70%' }}
                />
              }
            >
              <Card.Meta title='🌐 Single page landing site for World Peace Token altcoin' />
            </Card>
            </Col>
            <Col className='example-link hover-link' flex={'1 1 200px'}  onClick={() => window.location.assign('/bls')}>
            <Card  
              title='Baby Landshare'
              size='small'
              style={{ background: 'rgb(20 104 255)'}}

              cover={
                <img
                  alt="baby landshare peace token example"
                  src={blsscreen}
                  style={{ maxWidth: 600, margin: '0 auto', maxWidth: '70%' }}
                />
              }
            >
              <Card.Meta title='🌐 Single page landing site for BabyLandshare altcoin' />
            </Card>
            </Col>
        </Row>
        <br />
        <Row gutter={[30, 30]} >
        <Col className='example-link hover-link' xclassName='example-link hover-link' flex={'1 1 200px'} onClick={toggleContactOpen}>
              <Card  
                title='aaronalnutt.com'
                size='small'
                style={{ background: 'rgb(20 104 255)'}}
                cover={
                  <img
                    alt="baby landshare peace token example"
                    src={aascreen}
                    style={{ width: 300, margin: '0 auto', maxWidth: '70%' }}
                  />
                }
              >
                <Card.Meta title='🌐 Tree-style bio page' />
              </Card>
            </Col>
            <Col className='' flex={'1 1 200px'}  onClick={() => window.location.assign('/bls')} />

        </Row>
        <br /><br />
        <Typography.Title level={3}>
          Web Apps
        </Typography.Title>
        <Row gutter={[30, 30]}>
        <Col className='example-link hover-link' flex={'1 1 200px'} onClick={() => window.location.assign('/monop.io')}>
            <Card 
            size='small'
              title='monop.io'
              style={{ background: 'rgb(20 104 255)'}}

              cover={
                <img
                  alt="monop.io example"
                  src={monopioscreen}
                  style={{ width: 260, margin: '0 auto' }}
                />
              }
            >
              <Card.Meta title='📱 Alternative to Monopoly cash' />
              <Card.Meta description={<i>Useful when an old Monopoly game is missing a bunch of cash</i>} />
            </Card>
            </Col>
            <Col className='example-link hover-link' flex={'1 1 200px'}  onClick={() => window.location.assign('/d3example')}>
            <Card  
              title='Water Pollution D3 Visualization'
              style={{ background: 'rgb(20 104 255)' }}
              cover={
                <img
                  alt="d3 visualization screenshot"
                  src={d3screen}
                  style={{ maxWidth: 490, margin: '0 auto' }}
                />
              }
            >
              <Card.Meta title='🌐 D3 visualization landing page' />
              <Card.Meta description='Project involving D3.js drawn over dynamic content' />

            </Card>
            </Col>
        </Row>
        <br /><br />
        <Typography.Title level={3}>
          WPF Desktop Apps
        </Typography.Title>
        <Row gutter={[30, 30]}>
        <Col className='example-link hover-link' flex={'1 1 200px'} onClick={() => nav('/ticket')}>
            <Card 
              title='Ticket Collection POS'
              style={{background: 'rgb(20 104 255)'}}
              cover={
                <img
                  alt="ticket collection summary screenshot"
                  src={ticket}
                  style={{ width: 700, margin: '0 auto', maxWidth: '100%' }}
                />
              }
            >
              <Card.Meta title='🎟 Ticket Collection System ' />
              <Card.Meta description='Point of Sales (POS)' />

            </Card>
            </Col>
            <Col className='' flex={'1 1 200px'}  onClick={() => window.location.assign('/bls')} />

        </Row>
        <br /><br />
        <br /><br />
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </section>
    </div>
  );
}

export default Examples;
