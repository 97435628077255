import React from 'react';
import avatarIco from './images/avatarbmnew.jpeg';
import { Tooltip } from 'antd';

const Header = () => {  
    return (
        <header onClick={() => window.location = '/'} className="App-header ">
            <div className='firework'></div>
            <div className='firework'></div>
            <div className='firework'></div>     
            <Tooltip title="Homepage">

            <img className="selfie" style={{ height: 160}} src={avatarIco}  />
            </Tooltip>
            <code onClick={() => window.location = '/'}>@aaronalnutt</code>
        </header>
        
    )
};

export default Header;