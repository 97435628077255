import './memes.css';
import patbench from './images/patbench.jpg';
import flatsquid from './images/flatsquid.jpg';
import patbrain from './images/patbrainexplode.jpg';
import gar from './images/Spongegar.webp';
function Memes (){
    return (
        <>
         <section class="gallery">
            <img class="meme" src={patbench} alt="Meme 1" />
        
            <img class="meme" src={flatsquid} alt="Meme 2" />
            <img class="meme" src={patbrain} alt="Meme 3" />
            <img class="meme" src={gar} alt="Meme 4" />

       
    </section>
    <div style={{ height: 500 }}></div>
        </>
    )
}

export default Memes;